
















































































































































































































































































































































































import SLACK_ARCHIVED_CHANNEL_ICON from '@/assets/icons/slack/slack-archived-channel.svg'
import SLACK_CHANNEL_ICON from '@/assets/icons/slack/slack-channel.svg'
import PRIVATE_SLACK_CHANNEL_ICON from '@/assets/icons/slack/slack-private-channel.svg'
import AutoPostBoostingErrors from '@/components/AutoPostBoosting/AutoPostBoostingErrors.vue'
import BaseSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import IntegrationService from '@/services/api/IntegrationService'
import PostBoostingService from '@/services/api/PostBoostingService'
import props from '@/views/AutoPostBoosting/steps/props'
import { AxiosResponse } from 'axios'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AutoPostBoostingAd',
  components: { NewUiAccordion, NewUiSwitcher, AutoPostBoostingErrors, BaseSelect },
  mixins: [props],
  data () {
    return {
      pixels: null,
      events: null,
      slack_channels: null,
      slackSearch: '',
      preventSlackDropdownClosing: false,
      notification_spec_loaded: false
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    slackInputSearchPointerDownHandler (e: PointerEvent): void {
      const target = e.target as HTMLInputElement | undefined

      if (target) {
        target.focus()
      }
    },
    templateNameNestedDropdownClickHandler (event: PointerEvent): void {
      let possiblyParent = event.target as HTMLElement
      while (possiblyParent) {
        if (possiblyParent.classList.contains('template-name-nested-dropdown')) {
          possiblyParent.dataset.open = possiblyParent.dataset.open === 'open' ? '' : 'open'
        }
        possiblyParent = possiblyParent.parentElement
      }
    },
    slackToggle (channel_id: string): void {
      this.slackValue.includes(channel_id)
        ? this.slackValue = this.slackValue.filter((_channel) => _channel !== channel_id)
        : this.slackValue.push(channel_id)
    },
    slackClear (): void {
      this.slackValue = []
    },
    emailToggle (email: string): void {
      this.$emit('remove-error', 'notification_spec')
      this.emailValue.includes(email)
        ? this.emailValue = this.emailValue.filter(_email => _email !== email)
        : this.emailValue.push(email)
    },
    emailClear (): void {
      this.$emit('remove-error', 'notification_spec')
      this.emailValue = []
    },
    frontEndErrorsHandler (): boolean {
      let result = false

      if (!this.data.url) {
        result = true
        this.$emit('set-error', 'url', 'This field is required!')
      }
      if (!this.data.url_parameter) {
        result = true
        this.$emit('set-error', 'url_parameter', 'This field is required!')
      }
      if (this.data.notification_spec.enabled) {
        if (
          this.data.notification_spec.emails.length === 0 &&
          this.data.notification_spec.slack_channels.length === 0
        ) {
          result = true
          this.$emit('set-error', 'notification_spec', 'At least one receiver is required')
        }
      }

      return result
    },
    postData (): unknown {
      const result = {
        ...this.data,
        notification_spec: {
          enabled: this.data.notification_spec.enabled
        },
        pixel_id: this.data.pixel_id,
        conversion_event: this.data.conversion_event
      }

      if (this.data.notification_spec.enabled) {
        result.notification_spec.emails = this.data.notification_spec.emails
        result.notification_spec.slack_channels = this.data.notification_spec.slack_channels
      }

      return result
    },
    async post () {
      return PostBoostingService.postAdSetting(this.service, this.ruleId, this.postData())
    },
    async getPixels (): Promise<unknown> {
      const axiosResponse = await PostBoostingService.getPixels(this.service, this.ruleId)
      this.pixels = axiosResponse.data.data
      return axiosResponse
    },
    async getEvents (): Promise<unknown> {
      const axiosResponse = await PostBoostingService.getEvents(this.service)
      this.events = axiosResponse.data.data
      return axiosResponse
    },
    async getAdRuleNotificationSpec () {
      this.notification_spec_loaded = false
      const axiosResponse: AxiosResponse = await PostBoostingService.getPostBoostingRule(this.service, this.ruleId, true)
      if (axiosResponse?.data?.included) {
        const { included } = axiosResponse.data
        const enabled = included.find(_ => _.type === 'notification_spec')?.attributes?.enabled

        if (enabled) {
          this.data.notification_spec.enabled = enabled
          const emails: Array<string> = []
          const slack_channels: Array<string> = []

          for (const include of included) {
            if (include?.type === 'receivers') {
              if (include?.attributes?.type === 'slack_channel' && include?.attributes?.address) {
                slack_channels.push(include?.attributes?.address)
              } else if (include?.attributes.type === 'email' && include?.attributes?.address) {
                emails.push(include?.attributes?.address)
              }
            }
          }
          this.data.notification_spec.emails = emails
          this.data.notification_spec.slack_channels = slack_channels
        }
      }
      this.notification_spec_loaded = true
      return axiosResponse
    },
    async getSlackChannels () {
      const axiosResponse = await IntegrationService.get_slack_channels()
      this.slack_channels = axiosResponse.data.data
      return axiosResponse
    }
  },
  computed: {
    ...mapGetters('profile', ['getEmail']),
    emailValue: {
      get (): Array<string> {
        return this.data.notification_spec.emails
      },
      set (value: Array<string>): void {
        this.data.notification_spec.emails = value
      }
    },
    pixel_id: {
      get (): string | null {
        return this.data.pixel_id
      },
      set (value: string | null): void {
        this.data.pixel_id = value
      }
    },
    conversion_event: {
      get (): string | null {
        return this.data.conversion_event
      },
      set (value: string | null): void {
        this.data.conversion_event = value
      }
    },
    slackValue: {
      get (): Array<string> {
        return this.data.notification_spec.slack_channels
      },
      set (value: Array<string>) {
        this.data.notification_spec.slack_channels = value
      }
    },
    callToActions (): Array<unknown> {
      if (!this.meta || !this.meta.call_to_actions) {
        return []
      }
      const { call_to_actions } = this.meta
      if (call_to_actions instanceof Array) {
        return call_to_actions
      }
      return call_to_actions.WEBSITE_WEBSITE ||
        // Lead method is required to choose INSTANT_FORM or CALL
        call_to_actions.INSTANT_FORM ||
        call_to_actions.CALL || []
    },
    _emailOptions (): Array<string> {
      return [this.getEmail]
    },
    emailOptions (): Array<string> {
      return this._emailOptions.map(_ => _).sort(
        (a, b) => {
          return (this.emailValue.includes(a) ? -1 : 1) + (this.emailValue.includes(b) ? 1 : -1)
        }
      )
    },
    _slackOptions (): Array<unknown> {
      return this.slack_channels && this.slack_channels.length
        ? this.slack_channels.map(c => ({
          label: c.attributes.name || c.attributes.external_id,
          value: c.attributes.external_id,
          icon: c.attributes.is_archived
            ? SLACK_ARCHIVED_CHANNEL_ICON
            : c.attributes.is_private
              ? PRIVATE_SLACK_CHANNEL_ICON
              : SLACK_CHANNEL_ICON
        }))
        : []
    },
    slackOptions (): Array<unknown> {
      return this._slackOptions.map(_ => _).sort(
        (a, b) => {
          return (this.slackValue.includes(a.value) ? -1 : 1) + (this.slackValue.includes(b.value) ? 1 : -1)
        }
      ).filter(channel => !this.slackSearch || channel.label.includes(this.slackSearch))
    },
    slackDropDownShouldOpen () {
      return ({ noDrop, open, mutableLoading }) =>
        this.preventSlackDropdownClosing || (noDrop ? false : open && !mutableLoading)
    }
  },
  created () {
    if (this.ruleId) {
      this.$emit('reload-ad-rule', () => {
        this.getAdRuleNotificationSpec()
        this.getPixels()
        this.getEvents()
        this.getSlackChannels()
      })
    } else {
      this.addNotification({ body: 'The rule has not been created yet!', type: 'danger' })
      this.$router.replace({
        name: 'PostBoostingNewDetails',
        params: { ...this.$route.params }
      })
    }
  }
})

